$gutter: 1rem;

@import 'node_modules/primeflex/primeflex.scss';
@import './AppDemo.scss';

body.loading * {
  overflow: hidden !important;
}

.layout-wrapper .layout-topbar .layout-topbar-logo {
  background: white;

  img {
    height: 42px;
  }
}

.login-body {
  background: url('../public/iam/economic-bg-2.webp') 100%;
  background-size: cover;
}

.login-body .login-panel .p-message button {
  margin-top: unset;
  display: unset;
  min-width: unset;
}

.fc-daygrid-dot-event .fc-event-title {
  white-space: break-spaces;
  word-break: break-word;
}

.layout-menu .active-menuitem-routelink, .layout-menu .active-menuitem-routelink .pi {
  color: black;
  font-weight: bold;
}

.formgrid.detail .p-disabled, .formgrid.detail .p-component:disabled {
  opacity: 1;
}

.formgrid.detail .p-dropdown-trigger,
.formgrid.detail .p-treeselect-trigger,
.formgrid.detail .p-fileupload-buttonbar {
  display: none !important;
}

.formgrid.detail .p-inputtext,
.formgrid.detail .p-treeselect,
.formgrid.detail .p-dropdown {
  border: none;
}

.formgrid.detail .p-inputtext,
.formgrid.detail .p-treeselect-label {
  border-bottom: 1px dashed;
}

.formgrid.detail .p-inputtext:enabled:focus {
  box-shadow: none;
}

.hide-button-bar .p-fileupload-buttonbar {
  display: none !important;
}

.p-datatable .p-datatable-tbody > tr.p-highlight,
.p-datatable.p-datatable-striped .p-datatable-tbody > tr.p-row-odd.p-highlight {
  background: #EEF2FF;
  color: #4338CA;
}

.topbar-menu.p-overlay-badge .p-badge {
  transform: translate(25%, 25%);
}

.layout-wrapper .layout-topbar .topbar-menu > li > .p-link .layout-profile-userinfo {
  vertical-align: baseline;
}

.layout-wrapper.layout-topbar-blue .layout-topbar {
  .layout-profile-role {
    position: fixed;
    right: 14px;
    top: 55px;
    color: antiquewhite;
    font-size: 10px;
  }
}